import React, { Component } from 'react';
import { Link } from 'gatsby';
// import SiteLogo from '../../images/unakite-icon.png';

class Footer extends Component {
  render() {
    // const { siteTitle } = this.props;
    return (
      <div
        style={{
          width: '100%',
          backgroundColor: 'rgb(153, 27, 30)',
          color: 'white'
        }}
      >
        <div
          className="container"
          style={{
            // margin: '0 auto',
            // maxWidth: 960,
            padding: '1.15rem 1.0875rem'
          }}
        >
          ©{' '}
          <Link
            to="/"
            style={{
              color: 'white',
              textDecoration: 'none'
            }}
          >
            Unakite
          </Link>{' '}
          {2019} | CMU | HCII
        </div>
      </div>
    );
  }
}

export default Footer;
