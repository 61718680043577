import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql, navigate } from 'gatsby';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ResponsiveSidebar from '../ResponsiveSidebar/ResponsiveSidebar';
import Container from '../Container/Container';
import ResponsiveAnchor from '../ResponsiveAnchor/ResponsiveAnchor';
import ResponsiveTopBar from '../ResponsiveTopBar/ResponsiveTopBar';
import { connect } from 'react-redux';
import { pathPrefix } from '../../../gatsby-config';
import MediaQuery from 'react-responsive';
import { getInitialVisitStatus } from '../../store/selectors';
import { onSetSidebarDocked, setInitialVisitDone } from '../../actions/layout';

class Layout extends Component {
  componentDidMount() {
    const { initialVisitStatus, setInitialVisitDone } = this.props;
    if (initialVisitStatus === true) {
      navigate(window.location.pathname);
      setInitialVisitDone();
    }
  }

  render() {
    const {
      children,
      pathName,
      initialVisitStatus,
      // setPostPageOn,
      // setPostPageOff,
      sidebarRoot
      // onSetSidebarDocked
    } = this.props;

    if (initialVisitStatus) {
      return null;
    }

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
                downloadLink
              }
            }
            allMarkdownRemark {
              edges {
                node {
                  fields {
                    slug
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const allPosts = data.allMarkdownRemark.edges.map(
            edge => edge.node.fields.slug
          );
          let onPostPage;
          if (typeof window !== 'undefined') {
            const path = window.location.pathname.replace(
              pathPrefix.slice(0, -1),
              ''
            );
            if (
              allPosts.indexOf(path) >= 0 ||
              allPosts.indexOf(path.slice(0, -1)) >= 0
            ) {
              // setPostPageOn();
              onPostPage = true;
            } else {
              // setPostPageOff();
              onPostPage = false;
            }
          }

          return (
            <MediaQuery maxWidth={1000}>
              {matches => {
                return (
                  <>
                    <Helmet
                      title={`${
                        pathName !== undefined
                          ? pathName + ' | '
                          : onPostPage
                          ? 'Docs | '
                          : ''
                      }${data.site.siteMetadata.title}`}
                      meta={[
                        {
                          name: 'description',
                          content:
                            'The Unakite Chrome extension is designed to help developers organize information forgaged from the web so that they could make better-informed programming decisions.'
                        },
                        {
                          name: 'keywords',
                          content:
                            'unakite, decision-making, programming support'
                        }
                      ]}
                    >
                      <html lang="en" />
                    </Helmet>
                    <Header
                      siteTitle={data.site.siteMetadata.title}
                      downloadLink={data.site.siteMetadata.downloadLink}
                      pathName={pathName}
                    />
                    {matches && onPostPage && (
                      <ResponsiveTopBar root={sidebarRoot} />
                    )}
                    {!matches && onPostPage && (
                      <ResponsiveSidebar root={sidebarRoot} />
                    )}
                    <Container
                      sidebarDocked={!matches}
                      onPostPage={onPostPage}
                      pathName={pathName}
                    >
                      {children}
                      {!onPostPage && <Footer />}
                    </Container>
                    {!matches && onPostPage && <ResponsiveAnchor />}
                  </>
                );
              }}
            </MediaQuery>
          );
        }}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

const mapStateToProps = state => {
  return {
    initialVisitStatus: getInitialVisitStatus(state)
  };
};

const mapDispatchToProps = {
  // setPostPageOn,
  // setPostPageOff,
  onSetSidebarDocked,
  setInitialVisitDone
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
