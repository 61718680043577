import React, { Component } from 'react';
import axios from 'axios';
import { chromeWebStoreVersionAPIEndPoint } from '../shared/constants';
import { Link, graphql, StaticQuery } from 'gatsby';

class Menu extends Component {
  state = {
    chromeWebStoreVersion: '2'
  };

  componentDidMount() {
    axios
      .get(chromeWebStoreVersionAPIEndPoint)
      .then(response => {
        let versionString = response.data.chromeWebStoreVersion;
        this.setState({ chromeWebStoreVersion: versionString });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                downloadLink
              }
            }
            allMenuItems {
              edges {
                node {
                  name
                  link
                }
              }
            }
          }
        `}
        render={data => {
          const menuItems = data.allMenuItems.edges
            .map(edge => edge.node)
            .reverse();
          return (
            <div>
              <div style={{ marginLeft: '2em', float: 'right' }}>
                <p style={{ margin: 0, fontSize: '0.9rem', fontWeight: 400 }}>
                  <a
                    href={data.site.siteMetadata.downloadLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'inherit', textDecoration: 'none' }}
                  >
                    Download (v{this.state.chromeWebStoreVersion})
                  </a>
                </p>
              </div>
              {menuItems.map((item, idx) => {
                return (
                  <div style={{ marginLeft: '2em', float: 'right' }} key={idx}>
                    <p
                      style={{ margin: 0, fontSize: '0.9rem', fontWeight: 400 }}
                    >
                      <Link
                        to={item.link}
                        style={{ color: 'inherit', textDecoration: 'none' }}
                      >
                        {item.name}
                      </Link>
                    </p>
                  </div>
                );
              })}
            </div>
          );
        }}
      />
    );
  }
}

export default Menu;
