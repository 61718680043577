import React, { Component } from 'react';
import { Link } from 'gatsby';
import sizeMe from 'react-sizeme';
import { connect } from 'react-redux';
import { updateHeaderHeight } from '../../actions/layout';
import SiteLogo from '../../images/unakite-icon.png';
import Menu from '../Menu/Menu';

class Header extends Component {
  componentDidUpdate = () => {
    if (this.props.size.height)
      this.props.updateHeaderHeight(this.props.size.height);
  };

  render() {
    const { siteTitle, pathName } = this.props;
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 100000,
          backgroundColor:
            pathName === 'Home' ? 'rgb(247, 247, 247)' : 'rgba(45,45,45,0.98)',
          color: pathName === 'Home' ? 'black' : 'white',
          boxShadow:
            pathName === 'Home' ? null : '0 1px 15px rgba(50,50,93,.2)',
          marginBottom: '1.45rem'
        }}
      >
        <div
          style={{
            color: 'inherit',
            margin: '0 auto',
            maxWidth: 1360,
            padding: '0.8rem 1.0875rem'
          }}
        >
          <div
            style={{
              float: 'left',
              marginBottom: '0.8em',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Link
              to="/"
              style={{
                marginBottom: 0
              }}
            >
              <img
                alt=""
                src={SiteLogo}
                style={{
                  width: '1.5rem',
                  height: '1.5rem',
                  borderRadius: '50%',
                  border: '1px solid white',
                  boxShadow: '0 1px 15px rgba(50,50,93,.2)',
                  transition: 'all 0.1s ease-in',
                  marginBottom: 0,
                  marginRight: 8
                }}
              />
            </Link>
            <h1 style={{ margin: 0, fontSize: '0.9rem', fontWeight: 400 }}>
              <Link
                to="/"
                style={{
                  color: pathName === 'Home' ? 'black' : 'white',
                  textDecoration: 'none'
                }}
              >
                {siteTitle}
              </Link>
            </h1>
          </div>
          <Menu />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateHeaderHeight
};

export default connect(
  () => ({}),
  mapDispatchToProps
)(
  sizeMe({ monitorHeight: true, refreshMode: 'debounce', noPlaceholder: true })(
    Header
  )
);

// export default Header;
