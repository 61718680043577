import React, { Component } from 'react';
import styled from 'styled-components';
import SidebarContents from '../SidebarContents/SidebarContents';
import { connect } from 'react-redux';
import { getHeaderHeightState } from '../../store/selectors';
import './ResponsiveSidebar.css';

const SidebarInnerContainer = styled.div`
  position: absolute;
  left: 0;
  right: 10;
  top: 0;
  bottom: 0;
  overflow: hidden;
  &:hover {
    overflow: auto;
  }
`;

class ResponsiveSidebar extends Component {
  render() {
    const { headerHeight, root } = this.props;
    // console.log(headerHeight);
    return (
      <div
        style={{
          position: 'fixed',
          top: headerHeight + 30,
          left: 10,
          right: '80%',
          bottom: 0,
          overflow: 'hidden'
        }}
      >
        {/* <div
          className="SidebarContent"
          // style={{
          //   position: 'absolute',
          //   left: 0,
          //   right: 10,
          //   top: 0,
          //   bottom: 0,
          //   overflow: 'auto'
          // }}
        > */}
        <SidebarInnerContainer>
          <SidebarContents root={root} />
        </SidebarInnerContainer>

        {/* </div> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    headerHeight: getHeaderHeightState(state)
  };
};

export default connect(mapStateToProps)(ResponsiveSidebar);
