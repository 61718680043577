import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getHeaderHeightState } from '../../store/selectors';

class Container extends Component {
  render() {
    const { sidebarDocked, headerHeight, onPostPage, pathName } = this.props;

    return (
      <div
        style={{
          position: 'absolute',
          top:
            !sidebarDocked && onPostPage
              ? headerHeight + 70
              : onPostPage
              ? headerHeight + 30
              : headerHeight,
          left: (!sidebarDocked && onPostPage) || !onPostPage ? 0 : '20%',
          right: (!sidebarDocked && onPostPage) || !onPostPage ? 0 : '15%',
          bottom: 0,
          // marginTop:
          //   pathName === 'Home'
          //     ? headerHeight
          //     : !sidebarDocked && onPostPage
          //     ? headerHeight + 70
          //     : headerHeight + 30,
          // marginLeft: (!sidebarDocked && onPostPage) || !onPostPage ? 0 : '20%',
          // marginRight:
          //   (!sidebarDocked && onPostPage) || !onPostPage ? 0 : '15%',
          overflow: !sidebarDocked ? 'auto' : 'visible'
        }}
      >
        {pathName === 'Home' || pathName === '404' ? (
          <div>{this.props.children}</div>
        ) : (
          <div
            style={{
              margin: '0 auto',
              maxWidth: 960,
              padding: '0px 1.0875rem 1.45rem',
              paddingTop: 0
            }}
          >
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    headerHeight: getHeaderHeightState(state)
  };
};

export default connect(mapStateToProps)(Container);
